import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../firebase/firebase";
import { ExpenseSchema } from "../../app/pages/Space/validation";

class ExpensesRepository {
  static async create(data: ExpenseSchema) {
    const expensesRef = collection(db, "expenses");
    const docRef = await addDoc(expensesRef, data);
    return {
      id: docRef.id,
      ...data,
    } as ExpenseDocument;
  }

  static async update(expenseId: string, data: ExpenseSchema) {
    const docRef = doc(db, "expenses", expenseId);
    await updateDoc(docRef, data);
    return {
      id: docRef.id,
      ...data,
    } as ExpenseDocument;
  }

  static destroy(expenseId: string) {
    return deleteDoc(doc(db, "expenses", expenseId));
  }
}

export default ExpensesRepository;
