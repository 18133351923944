import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface AuthState {
  token: any;
  user: any;
}

const initialState: AuthState = {
  token: null,
  user: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuth: (state, action: PayloadAction<{ token: any; user: any }>) => {
      state.token = action.payload.token;
      state.user = action.payload.user;
    },
  },
});

export const { setAuth } = authSlice.actions;
export default authSlice.reducer;
