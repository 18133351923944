import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import AppConfigs from "../configs";

const app = initializeApp(AppConfigs.FIREBASE);
const auth = getAuth(app);
const db = getFirestore(app);

const ggProvider = new GoogleAuthProvider();

export default app;
export { db, auth, ggProvider };
