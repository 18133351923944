import { ChakraProvider } from "@chakra-ui/react";
import Routing from "./Routing";
import { Provider } from "react-redux";
import { persistor, store } from "../store/store";
import { PersistGate } from "redux-persist/integration/react";
import ErrorBoundary from "./components/ErrorBoundary";

function App() {
  return (
    <ChakraProvider>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ErrorBoundary>
            <Routing />
          </ErrorBoundary>
        </PersistGate>
      </Provider>
    </ChakraProvider>
  );
}

export default App;
