import { RouterProvider, createBrowserRouter } from "react-router-dom";
import NotFound from "./pages/NotFound";
import Login from "./pages/Login";
import Home from "./pages/Home";
import Space from "./pages/Space";
import { useAppDispatch } from "../store/store";
import { useEffect } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { setAuth } from "../store/auth";
import { auth } from "../firebase/firebase";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
    children: [
      {
        path: "/spaces/:spaceId",
        element: <Space />,
      },
    ],
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "*",
    element: <NotFound />,
  },
]);

export default function Routing() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        dispatch(setAuth({ token: null, user }));
      } else {
        dispatch(setAuth({ token: null, user: null }));
      }
    });
  }, [dispatch]);

  return <RouterProvider router={router} />;
}
