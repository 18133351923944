import { Box, Show, useColorModeValue } from "@chakra-ui/react";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../firebase/firebase";
import { useAuth } from "../../store/hooks";
import { useEffect, useState } from "react";
import SidebarDrawer from "./SidebarDrawer";
import SidebarContent from "./SidebarContent";

export default function Sidebar() {
  const [spaces, setSpaces] = useState<any[]>();
  const { user } = useAuth();

  const handleAdded = (space: any) => {
    setSpaces((curr) => [space, ...(curr ?? [])]);
  };

  useEffect(() => {
    const spacesRef = collection(db, "spaces");
    const q = query(spacesRef, where("uid", "==", user.uid));

    getDocs(q).then((querySnapshot) => {
      const spaces = querySnapshot.docs;
      setSpaces([...spaces.map((each) => ({ id: each.id, ...each.data() }))]);
    });
  }, [user.uid]);

  return (
    <>
      <Show above="md">
        <Box
          h="full"
          w={{ base: "full", md: 60 }}
          bg={useColorModeValue("gray.100", "gray.900")}
          p={2}
        >
          <SidebarContent spaces={spaces ?? []} onAddedSuccess={handleAdded} />
        </Box>
      </Show>
      <Show below="md">
        <SidebarDrawer>
          <SidebarContent spaces={spaces ?? []} onAddedSuccess={handleAdded} />
        </SidebarDrawer>
      </Show>
    </>
  );
}
