import {
  Heading,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import { useAppToast } from "../../../utils/hooks";
import ExpensesRepository from "../../../repository/expenses";
import MeticsRepository from "../../../repository/metrics";
import { AiFillDelete } from "react-icons/ai";

export default function ExpenseItemDelete({
  expense,
}: {
  expense: ExpenseDocument;
}) {
  const toast = useAppToast();

  const deleteItem = () => {
    ExpensesRepository.destroy(expense.id);
    MeticsRepository.decrement({
      spaceId: expense.spaceId,
      amount: expense.amount,
      type: expense.type,
    });
    toast({
      title: `Deleted: ${expense.title}`,
      status: "success",
    });
  };

  return (
    <Menu>
      <MenuButton
        size={"sm"}
        as={IconButton}
        aria-label="Options"
        icon={<AiFillDelete color="tomato" />}
        variant="outline"
      />
      <MenuList>
        <Heading as="h6" fontSize={"md"} p={3}>
          Confirm
        </Heading>
        <MenuItem icon={<AiFillDelete color="tomato" />} onClick={deleteItem}>
          Delete
        </MenuItem>
      </MenuList>
    </Menu>
  );
}
