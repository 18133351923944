import ExpenseForm from "./ExpenseForm";

export default function ExpenseItemNew({
  spaceId,
  onAddedSuccess,
}: {
  spaceId: string;
  onAddedSuccess: (value: ExpenseDocument) => void;
}) {
  return (
    <ExpenseForm
      sourceId={spaceId}
      onAddedSuccess={onAddedSuccess}
      type="new"
    />
  );
}
