import { useEffect, useState } from "react";
import { db } from "../../../firebase/firebase";
import {
  collection,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";

export default function useExpenses(spaceId: string | undefined) {
  const [expenses, setExpenses] = useState<ExpenseDocument[]>();

  const handleAdded = (value: ExpenseDocument) => {
    setExpenses((curr) => [value, ...(curr ?? [])]);
  };

  useEffect(() => {
    if (!spaceId) return;

    setExpenses([]);
    const expensesRef = collection(db, "expenses");
    const q = query(
      expensesRef,
      where("spaceId", "==", spaceId),
      orderBy("timestamp", "desc")
    );

    getDocs(q).then((querySnapshot) => {
      const spaces = querySnapshot.docs;
      const list = [
        ...spaces.map(
          (each) => ({ id: each.id, ...each.data() } as ExpenseDocument)
        ),
      ];
      setExpenses(list);
    });

    const subsQuery = query(expensesRef, where("spaceId", "==", spaceId));
    const unsubscribe = onSnapshot(subsQuery, (snapshot) => {
      snapshot.docChanges().forEach((change) => {
        const expense = {
          id: change.doc.id,
          ...change.doc.data(),
        } as ExpenseDocument;

        if (change.type === "added") {
          // setExpenses((curr) => [expense, ...(curr ?? [])]);
        }
        if (change.type === "modified") {
          setExpenses((curr) =>
            curr?.map((each) => {
              if (each.id === expense.id) {
                return expense;
              } else {
                return each;
              }
            })
          );
        }
        if (change.type === "removed") {
          setExpenses((curr) => {
            return curr?.filter((each) => each.id !== expense.id) ?? [];
          });
        }
      });
    });

    return unsubscribe;
  }, [spaceId]);

  return { expenses, handleAdded };
}
