import { Outlet, useNavigate, useOutlet } from "react-router-dom";
import Header from "../components/Header";
import {
  Grid,
  GridItem,
  Heading,
  Stack,
  useColorModeValue,
} from "@chakra-ui/react";
import { useEffect } from "react";
import { useAuth } from "../../store/hooks";
import Sidebar from "../components/Sidebar";

export default function Home() {
  const { user } = useAuth();
  const navigate = useNavigate();
  const outlet = useOutlet();
  const bg = useColorModeValue("gray.50", "gray.800");

  useEffect(() => {
    if (!user) {
      navigate("/login");
    }
  }, [navigate, user]);

  if (!user) return null;

  return (
    <Grid
      h="full"
      w="full"
      templateRows={{ base: "auto 1fr", md: "auto 1fr" }}
      templateColumns={{
        base: "1fr",
        md: "minmax(0, auto) 1fr",
      }}
    >
      <GridItem rowSpan={1} colSpan={{ base: 1, md: 2 }}>
        <Header />
      </GridItem>
      <Sidebar />
      <GridItem
        id="main"
        rowSpan={1}
        colSpan={1}
        overflow="hidden"
        minH={1}
        bg={bg}
      >
        <Stack h="full" w="full" overflow={"hidden"}>
          {outlet ? <Outlet /> : <Heading>Chon Space</Heading>}
        </Stack>
      </GridItem>
    </Grid>
  );
}
