import {
  AbsoluteCenter,
  Box,
  HStack,
  Heading,
  ScaleFade,
  SlideFade,
  Text,
  VStack,
} from "@chakra-ui/react";
import { BsCurrencyDollar } from "react-icons/bs";
import { formatCurrency } from "../../../utils";
import ExpenseItemDelete from "./ExpenseItemDelete";
import ExpenseItemEdit from "./ExpenseItemEdit";

export default function ExpenseItem({ expense }: { expense: ExpenseDocument }) {
  return (
    <SlideFade in offsetY="-30px">
      <Box w="full" minH="80px" alignItems={"stretch"} px={6} display={"flex"}>
        <Box display={"flex"} alignItems={"center"}>
          <Text>
            {new Date(expense.timestamp.toDate()).toLocaleDateString()}
          </Text>
        </Box>
        <Box width={"1px"} mx="20px" pos="relative">
          <ScaleFade initialScale={0.2} in style={{ height: "100%" }}>
            <Box
              h="full"
              width={"1px"}
              bg={expense.type === "coming" ? "green" : "red"}
            ></Box>
          </ScaleFade>
          <AbsoluteCenter>
            <Box p={1} rounded={"full"} bg="white">
              <BsCurrencyDollar
                color={expense.type === "coming" ? "green" : "red"}
                size={15}
              />
            </Box>
          </AbsoluteCenter>
        </Box>
        <VStack alignItems={"start"} gap={1} py={3} flexGrow={1}>
          <Heading as="h6" fontSize={"md"}>
            {expense.title}
          </Heading>
          <Text as="h5" color={expense.type === "coming" ? "green" : "red"}>
            {formatCurrency(expense.amount ?? 0)}
          </Text>
        </VStack>
        <VStack py={3}>
          <Text as="h5" color={"gray.500"}>
            {new Date(expense.timestamp.toDate()).toLocaleTimeString()}
          </Text>
          <HStack>
            <ExpenseItemEdit expense={expense} />
            <ExpenseItemDelete expense={expense} />
          </HStack>
        </VStack>
      </Box>
    </SlideFade>
  );
}
