import { object, mixed, string, number, InferType, ObjectSchema } from "yup";

export const expenseSchema: ObjectSchema<Expense> = object({
  type: mixed<"coming" | "spending">().oneOf(["coming", "spending"]).required(),
  title: string().required(),
  amount: number().min(1).max(1000000000000).required(),
  spaceId: string().required(),
  timestamp: mixed().required(),
});

export type ExpenseSchema = InferType<typeof expenseSchema>;
