import {
  Divider,
  HStack,
  Heading,
  Stack,
  Text,
  VStack,
  useColorModeValue,
} from "@chakra-ui/react";
import { BsCurrencyDollar } from "react-icons/bs";
import { formatCurrency } from "../../../utils";
import { useEffect, useState } from "react";
import MeticsRepository from "../../../repository/metrics";

export default function Overview({ spaceId }: { spaceId: string | undefined }) {
  const [metrics, setMetrics] = useState<any>();
  const bg = useColorModeValue("white", "gray.700");

  const remaining = (metrics?.coming ?? 0) - (metrics?.spending ?? 0);

  useEffect(() => {
    if (!spaceId) return;
    const unsub = MeticsRepository.subscribeMetrics(spaceId, setMetrics);
    return unsub;
  }, [spaceId]);

  return (
    <VStack alignItems={"start"}>
      <Heading as="h2" size="lg">
        Tổng quan
      </Heading>
      <Stack bg={bg} boxShadow="base" rounded="md" py={3} px={6} minW={"300px"}>
        <HStack>
          <Text w="80px">Đã chi:</Text>
          <BsCurrencyDollar color={"green"} />
          <Text>{formatCurrency(metrics?.coming ?? 0)}</Text>
        </HStack>
        <HStack>
          <Text w="80px">Đã thu:</Text>
          <BsCurrencyDollar color={"red"} />
          <Text>{formatCurrency(metrics?.spending ?? 0)}</Text>
        </HStack>
        <Divider />
        <HStack>
          <Text w="80px">Còn lại:</Text>
          <BsCurrencyDollar color={remaining < 0 ? "red" : "green"} />
          <Text>{formatCurrency(remaining)}</Text>
        </HStack>
      </Stack>
    </VStack>
  );
}
