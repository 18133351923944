import {
  Box,
  Button,
  Input,
  Radio,
  RadioGroup,
  Stack,
  VStack,
  useColorModeValue,
} from "@chakra-ui/react";
import { Timestamp } from "firebase/firestore";
import { format } from "currency-formatter";
import MeticsRepository from "../../../repository/metrics";
import { useAppToast } from "../../../utils/hooks";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { ExpenseSchema, expenseSchema } from "./validation";
import { yupResolver } from "@hookform/resolvers/yup";
import ExpensesRepository from "../../../repository/expenses";

export default function ExpenseForm({
  type,
  sourceId,
  onAddedSuccess,
  onUpdatedSuccess,
  defaultValues = {
    amount: 1000,
    title: "",
    spaceId: sourceId,
    type: "spending",
    timestamp: Timestamp.now(),
  },
}: {
  type: "new" | "edit";
  sourceId: any;
  onAddedSuccess?: (value: ExpenseDocument) => void;
  onUpdatedSuccess?: (value: ExpenseDocument) => void;
  defaultValues?: ExpenseSchema;
}) {
  const toast = useAppToast();
  const bg = useColorModeValue("gray.100", "gray.900");

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    control,
  } = useForm<ExpenseSchema>({
    defaultValues,
    resolver: yupResolver(expenseSchema),
  });

  const amount = watch("amount");

  const onSubmit: SubmitHandler<ExpenseSchema> = async (data) => {
    if (type === "new") {
      const result = await ExpensesRepository.create(data);
      await MeticsRepository.increment(sourceId, data.amount ?? 0, data.type);
      onAddedSuccess?.(result);
      toast({
        title: `Added: ${data.title}`,
        status: "success",
      });
    } else {
      const result = await ExpensesRepository.update(sourceId, data);
      await MeticsRepository.decrement({
        spaceId: defaultValues.spaceId,
        amount: defaultValues.amount ?? 0,
        type: defaultValues.type,
      });
      await MeticsRepository.increment(
        defaultValues.spaceId,
        data.amount ?? 0,
        data.type
      );
      onUpdatedSuccess?.(result);
      toast({
        title: `Updated: ${result.title}`,
        status: "success",
      });
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <VStack gap={3}>
        <Controller
          control={control}
          rules={{
            required: true,
          }}
          render={({ field: { onChange, onBlur, value } }) => (
            <RadioGroup
              defaultValue="spending"
              onBlur={onBlur}
              onChange={onChange}
              value={value}
            >
              <Stack spacing={5} direction="row">
                <Radio colorScheme="red" value="spending">
                  Chi
                </Radio>
                <Radio colorScheme="green" value="coming">
                  Thu
                </Radio>
              </Stack>
            </RadioGroup>
          )}
          name="type"
        />

        <Input
          placeholder="Tiêu đề"
          bg={bg}
          isInvalid={!!errors.title}
          errorBorderColor="crimson"
          {...register("title")}
        />
        <Input
          placeholder="Số tiền"
          bg={bg}
          type="number"
          isInvalid={!!errors.amount}
          errorBorderColor="crimson"
          {...register("amount")}
        />
        <Box as="span" color="gray.600" fontSize="sm">
          {format(amount, {
            code: "VND",
            thousand: ",",
            decimal: ".",
          })}
        </Box>
        <Button colorScheme="teal" variant="solid" type="submit">
          {type === "new" ? "Thêm" : "Lưu"}
        </Button>
      </VStack>
    </form>
  );
}
