import {
  Drawer,
  DrawerCloseButton,
  DrawerContent,
  useBreakpoint,
} from "@chakra-ui/react";
import { useAppDispatch } from "../../store/store";
import { useSidebar } from "../../store/hooks";
import { closeSidebar } from "../../store/sidebar";
import { ReactNode } from "react";

export default function SidebarDrawer({ children }: { children: ReactNode }) {
  const dispatch = useAppDispatch();
  const { open } = useSidebar();
  const breakpoint = useBreakpoint({ ssr: false });

  const onClose = () => {
    dispatch(closeSidebar());
  };

  return (
    <Drawer
      isOpen={breakpoint === "base" || breakpoint === "sm" ? open : false}
      placement="left"
      onClose={onClose}
      returnFocusOnClose={false}
      onOverlayClick={onClose}
      size="full"
    >
      <DrawerContent py={6} px={3}>
        <DrawerCloseButton size={"lg"} />
        {children}
      </DrawerContent>
    </Drawer>
  );
}
