import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  IconButton,
  useDisclosure,
} from "@chakra-ui/react";
import { useRef } from "react";
import { AiFillEdit } from "react-icons/ai";
import ExpenseForm from "./ExpenseForm";

export default function ExpenseItemEdit({
  expense,
}: {
  expense: ExpenseDocument;
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = useRef<any>();

  return (
    <>
      <IconButton aria-label="Delete" size={"sm"} onClick={onOpen}>
        <AiFillEdit />
      </IconButton>
      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        finalFocusRef={btnRef}
        size={{ base: "full", md: "md" }}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Chi tiết</DrawerHeader>

          <DrawerBody>
            <ExpenseForm
              defaultValues={{ ...expense }}
              type="edit"
              sourceId={expense.id}
            />
          </DrawerBody>

          <DrawerFooter>
            <Button variant="outline" mr={3} onClick={onClose}>
              Cancel
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
}
