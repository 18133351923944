import {
  Box,
  Button,
  Center,
  Heading,
  Input,
  List,
  ListIcon,
  ListItem,
  useColorModeValue,
} from "@chakra-ui/react";
import { addDoc, collection } from "firebase/firestore";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppToast } from "../../utils/hooks";
import { db } from "../../firebase/firebase";
import { useAuth } from "../../store/hooks";
import { MdCheckCircle } from "react-icons/md";
import { closeSidebar } from "../../store/sidebar";
import { useAppDispatch } from "../../store/store";

export default function SidebarContent({
  spaces,
  onAddedSuccess,
}: {
  spaces: any[];
  onAddedSuccess: (space: any) => void;
}) {
  const dispatch = useAppDispatch();
  const toast = useAppToast();
  const { user } = useAuth();
  const [spaceName, setSpaceName] = useState<string>();
  const navigate = useNavigate();

  const clickSpaceHOF = (id: string) => () => {
    navigate("/spaces/" + id);
    dispatch(closeSidebar());
  };

  const add = async () => {
    if (!spaceName) return;

    const spacesRef = collection(db, "spaces");
    const docRef = await addDoc(spacesRef, {
      name: spaceName,
      uid: user.uid,
    });
    const newSpace = {
      id: docRef.id,
      ...{
        name: spaceName,
        uid: user.uid,
      },
    };
    onAddedSuccess(newSpace);
    toast({
      title: `Added: ${spaceName}`,
      status: "success",
    });
    setSpaceName(undefined);
  };

  return (
    <>
      <Heading>Spaces</Heading>
      <List spacing={3} my={5}>
        {spaces?.map((space) => (
          <ListItem key={space.id} onClick={clickSpaceHOF(space.id)}>
            <Box
              border={"1px"}
              borderStyle={"solid"}
              borderColor={"gray.500"}
              px={3}
              py={1}
              rounded={"md"}
              cursor={"pointer"}
            >
              <ListIcon as={MdCheckCircle} color="green.500" />
              {space.name}
            </Box>
          </ListItem>
        ))}
      </List>
      <Center gap={1}>
        <Input
          value={spaceName ?? ""}
          placeholder="Space name"
          bg={useColorModeValue("gray.100", "gray.900")}
          onChange={(event) => setSpaceName(event.target.value)}
        />
        <Button colorScheme="teal" variant="solid" onClick={add}>
          Tạo
        </Button>
      </Center>
    </>
  );
}
