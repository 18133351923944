import { doc, getDoc, increment, onSnapshot, setDoc } from "firebase/firestore";
import { db } from "../../firebase/firebase";

class MeticsRepository {
  static getMetrics(spaceId: string) {
    const ref = doc(db, "metrics", spaceId);
    return getDoc(ref);
  }

  static subscribeMetrics(spaceId: string, setData: any) {
    const ref = doc(db, "metrics", spaceId);
    return onSnapshot(
      ref,
      (snapshot) => {
        setData({ id: snapshot.id, ...snapshot.data() });
      },
      () => {
        console.log("Error: MeticsRepository.subscribeMetrics");
      }
    );
  }

  static increment(
    spaceId: string,
    amount: number,
    type: "coming" | "spending"
  ) {
    return setDoc(
      doc(db, "metrics", spaceId),
      {
        [type]: increment(amount),
      },
      { merge: true }
    );
  }

  static decrement(args: {
    spaceId: string;
    amount: number;
    type: "coming" | "spending";
  }) {
    return setDoc(
      doc(db, "metrics", args.spaceId),
      {
        [args.type]: increment(-args.amount),
      },
      { merge: true }
    );
  }
}
export default MeticsRepository;
