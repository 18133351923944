import {
  Box,
  Flex,
  Avatar,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  useColorModeValue,
  Stack,
  useColorMode,
  Center,
  Text,
  useToast,
  IconButton,
  HStack,
} from "@chakra-ui/react";
import { FiMenu } from "react-icons/fi";
import { MoonIcon, SunIcon } from "@chakra-ui/icons";
import { auth } from "../../firebase/firebase";
import { signOut } from "firebase/auth";
import { setAuth } from "../../store/auth";
import { useAppDispatch } from "../../store/store";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../store/hooks";
import { toggleSidebar } from "../../store/sidebar";

export default function Header() {
  const toast = useToast();
  const { user } = useAuth();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { colorMode, toggleColorMode } = useColorMode();

  const onToggleSidebar = () => {
    dispatch(toggleSidebar({}));
  };

  const logout = () => {
    signOut(auth)
      .then(() => {
        dispatch(setAuth({ token: null, user: null }));
        navigate("/login");
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        toast({
          title: `Error: ${errorCode} - ${errorMessage}`,
          position: "top",
          status: "error",
          isClosable: true,
        });
      });
  };

  return (
    <>
      <Box bg={useColorModeValue("gray.100", "gray.900")} px={4}>
        <Flex h={16} alignItems={"center"} justifyContent={"space-between"}>
          <HStack gap={10}>
            <IconButton
              variant="outline"
              aria-label="open menu"
              icon={<FiMenu />}
              onClick={onToggleSidebar}
            />
            <Box>Hello!</Box>
          </HStack>

          <Flex alignItems={"center"}>
            <Stack direction={"row"} spacing={7}>
              <Button onClick={toggleColorMode}>
                {colorMode === "light" ? <MoonIcon /> : <SunIcon />}
              </Button>

              <Menu>
                <MenuButton
                  as={Button}
                  rounded={"full"}
                  variant={"link"}
                  cursor={"pointer"}
                  minW={0}
                >
                  <Avatar
                    size={"sm"}
                    name={user.displayName}
                    src={user.photoURL || undefined}
                  />
                </MenuButton>
                <MenuList alignItems={"center"}>
                  <br />
                  <Center>
                    <Avatar
                      size={"2xl"}
                      name={user.displayName}
                      src={user.photoURL || undefined}
                    />
                  </Center>
                  <br />
                  <Center>
                    <Text>{user.displayName}</Text>
                  </Center>
                  <Center>
                    <Text color="gray.500">{user.email}</Text>
                  </Center>
                  <br />
                  <MenuDivider />
                  <MenuItem onClick={logout}>Logout</MenuItem>
                </MenuList>
              </Menu>
            </Stack>
          </Flex>
        </Flex>
      </Box>
    </>
  );
}
