import {
  Box,
  Divider,
  Heading,
  List,
  Stack,
  VStack,
  useColorModeValue,
} from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import Overview from "./Overview";
import ExpenseItemNew from "./ExpenseItemNew";
import useExpenses from "./useExpenses";
import ExpenseItem from "./ExpenseItem";

export default function Space() {
  const { spaceId } = useParams();
  const bg = useColorModeValue("white", "gray.700");

  const { expenses, handleAdded } = useExpenses(spaceId);

  return (
    <Box overflow={"auto"} id="space-page">
      <Stack py={3} px={6} flexDirection={{ base: "column", md: "row" }}>
        {spaceId && (
          <ExpenseItemNew spaceId={spaceId} onAddedSuccess={handleAdded} />
        )}
        <VStack
          alignItems={"start"}
          py={3}
          px={{ base: 0, md: 6 }}
          overflowX={"hidden"}
          flexWrap={"nowrap"}
          flexGrow={1}
        >
          <Overview spaceId={spaceId} />
          <Divider />
          <Heading as="h2" size="lg">
            Lịch sử
          </Heading>
          <List w="full" bg={bg} boxShadow="base" rounded="md">
            {expenses?.map((expense) => (
              <ExpenseItem key={expense.id} expense={expense} />
            ))}
          </List>
        </VStack>
        {/* <VStack
        py={3}
        px={{ base: 0, md: 6 }}
        alignItems={{ base: "start", md: "center" }}
        flexWrap={"nowrap"}
      >
        <Heading as="h2" size="lg">
          Charts
        </Heading>
      </VStack> */}
      </Stack>
    </Box>
  );
}
