import {
  Button,
  Card,
  Center,
  Container,
  Heading,
  VStack,
  useColorModeValue,
  CardBody,
  CardHeader,
  useToast,
} from "@chakra-ui/react";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { auth, ggProvider } from "../../firebase/firebase";
import { setAuth } from "../../store/auth";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../store/store";
import { FcGoogle } from "react-icons/fc";
import { useAuth } from "../../store/hooks";
import { useEffect } from "react";

export default function Login() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { user } = useAuth();
  const toast = useToast();

  const login = () => {
    signInWithPopup(auth, ggProvider)
      .then((result) => {
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential?.accessToken;
        const user = result.user;
        dispatch(setAuth({ token, user }));
        navigate("/");
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // The email of the user's account used.
        const email = error.customData.email;
        toast({
          title: `Error: ${errorCode} - ${email} - ${errorMessage}`,
          position: "top",
          status: "error",
          isClosable: true,
        });
      });
  };

  useEffect(() => {
    if (user) {
      navigate("/");
    }
  }, [navigate, user]);

  return (
    <Container
      id="login-page"
      h="full"
      w="full"
      bg={useColorModeValue("gray.50", "gray.800")}
      maxW="8xl"
    >
      <Center h="full" w="full">
        <Card w="sm" h="md" boxShadow={"lg"}>
          <Center h="full" w="full">
            <VStack>
              <CardHeader>
                <Heading mb={6} textTransform={"uppercase"}>
                  Đăng nhập
                </Heading>
              </CardHeader>
              <CardBody>
                <Button
                  mb={8}
                  colorScheme="teal"
                  variant="outline"
                  onClick={login}
                  leftIcon={<FcGoogle />}
                >
                  Continue with Google
                </Button>
              </CardBody>
            </VStack>
          </Center>
        </Card>
      </Center>
    </Container>
  );
}
