import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface SidebarState {
  open: boolean;
}

const initialState: SidebarState = {
  open: true,
};

export const sidebarSlice = createSlice({
  name: "sidebar",
  initialState,
  reducers: {
    toggleSidebar: (state, action: PayloadAction<{ open?: boolean }>) => {
      if (action.payload.open !== undefined) {
        state.open = action.payload.open;
      } else {
        state.open = !state.open;
      }
    },
    closeSidebar: (state) => {
      state.open = false;
    },
    openSidebar: (state) => {
      state.open = true;
    },
  },
});

export const { toggleSidebar, closeSidebar, openSidebar } =
  sidebarSlice.actions;
export default sidebarSlice.reducer;
