import { UseToastOptions, useToast } from "@chakra-ui/react";
import { useRef } from "react";

export function useAppToast() {
  const toast = useToast();
  const toastIdRef = useRef<any>();

  const makeToast = (options: {
    title: string;
    status: UseToastOptions["status"];
  }) => {
    if (toastIdRef.current) {
      toast.close(toastIdRef.current);
    }
    toastIdRef.current = toast({
      title: options.title,
      position: "top",
      status: options.status,
      isClosable: true,
    });
  };

  return makeToast;
}
